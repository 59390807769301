import React, { useEffect, useState } from 'react'
import { useFaq } from '../../hooks/useFaq'
import { Accordion, AccordionItem } from '@hrk/huw-module-library'
import { AppLocale, MatomoEventTracker } from '@hrk/types'
import { MatomoTrackerContext } from '../../context/MatomoTrackerContext'

interface FlatFaqCategory {
  header: string
  items?: AccordionItem[]
}

interface BasicFaqProps {
  locale: AppLocale
}

export const BasicFaqs: React.FC<BasicFaqProps> = (props) => {
  const faq = useFaq()

  const [matomoTracker, setMatomoTracker] = useState<MatomoEventTracker | undefined>()
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setMatomoTracker(window['Matomo']?.getTracker())
    }
  }, [])

  const flatFaqCategories: FlatFaqCategory[] = []
  faq.forEach((faqCategory) => {
    const accordionEntries: AccordionItem[] = []
    if (faqCategory && faqCategory.attributes?.faqItems && faqCategory.attributes.locale === props.locale) {
      faqCategory.attributes.faqItems.forEach((faqItem) => {
        if (faqItem && faqItem.faq_item) {
          accordionEntries.push({
            title: faqItem.faq_item.data?.attributes?.question,
            text: faqItem.faq_item.data?.attributes?.answer,
          } as AccordionItem)
        }
      })
      if (accordionEntries.length > 0) {
        flatFaqCategories.push({ header: faqCategory.attributes.name, items: accordionEntries })
      }
    }
  })

  return (
    <MatomoTrackerContext.Provider value={{ matomoTracker }}>
      <div role="list">
        {flatFaqCategories.map((faqCategory, index) => (
          <div role="listitem" key={`faqcategory-${index + 1}`}>
            <h2>{faqCategory.header}</h2>
            <Accordion list={faqCategory.items} matomoEventTracker={matomoTracker} />
          </div>
        ))}
      </div>
    </MatomoTrackerContext.Provider>
  )
}
