import { graphql, useStaticQuery } from 'gatsby'
import { Query, Strapi_FaqCategoryEntity } from 'gatsbyGraphqlTypes'

export const useFaq = (): Strapi_FaqCategoryEntity[] => {
  const { strapi } = useStaticQuery<Query>(
    graphql`
      query FaqItems {
        strapi {
          faqCategories(
            locale: "all"
            filters: { target: { eq: "frontend" } }
            sort: ["orderIdx"]
            pagination: { limit: 10000 }
          ) {
            data {
              attributes {
                name
                locale
                faqItems(pagination: { limit: 10000 }) {
                  faq_item {
                    data {
                      attributes {
                        question
                        answer
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  )

  const items = strapi.faqCategories?.data ?? []
  return items
}
