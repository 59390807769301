import React from 'react'
import { LayoutWrapper } from '../components/layout/layoutWrapper'
import { Seo } from '../components/seo/Seo'
import { AppLocale, GenerateLocales } from '@hrk/types'
import { useNavigationItems } from '../hooks/useNavigationItems'
import { BasicFaqs } from '../components/faq/basicFaqs'
import { useFaq } from '../hooks/useFaq'
import { FAQJsonLd } from 'gatsby-plugin-next-seo'
import { SiteMetadata, useSiteMetadata } from '../hooks/useSiteConfig'

const Faq: React.FC = () => {
  const currentSlug = '/en/faq'
  const currentLocale: AppLocale = GenerateLocales.en
  const seoTitle = 'FAQ'
  const seoDescription = 'Frequently asked questions and answers'

  const { collectBreadCrumbs } = useNavigationItems()
  const currentPagePath = collectBreadCrumbs({ slug: currentSlug, title: seoTitle, type: 'EditorialPages' })

  const faq = useFaq()
  const allQuestions: { question: string; answer: string }[] = []
  faq.forEach((faqCategory) => {
    if (faqCategory && faqCategory.attributes?.faqItems && faqCategory.attributes.locale === GenerateLocales.en) {
      faqCategory.attributes.faqItems.forEach((faqItem) => {
        if (faqItem && faqItem.faq_item && faqItem.faq_item.data?.attributes) {
          allQuestions.push({
            question: faqItem.faq_item.data?.attributes.question,
            answer: faqItem.faq_item.data?.attributes?.answer,
          })
        }
      })
    }
  })

  return (
    <>
      <Seo title={seoTitle} slug={currentSlug} locale={currentLocale} description={seoDescription}></Seo>
      <FAQJsonLd questions={allQuestions} />
      <LayoutWrapper
        currentLocale={currentLocale}
        currentTitle={seoTitle}
        currentSlug={currentSlug}
        currentPath={currentPagePath}
        otherLocales={[{ locale: GenerateLocales.de, slug: '/faq/' }]}
      >
        <BasicFaqs locale={GenerateLocales.en} />
      </LayoutWrapper>
    </>
  )
}

export const Head = ({ location, params, data, pageContext }) => {
  const site: SiteMetadata = useSiteMetadata(pageContext.locale as AppLocale)
  return <link rel="canonical" href={`${site.metadata.siteUrl}/en/faq/`} />
}

export default Faq
